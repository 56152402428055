/*******************************
         Site Overrides
*******************************/

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  background-image: none !important;
  box-shadow: @disabledBoxShadow !important;
  pointer-events: none !important;
}