.App {
  text-align: center;
}

.Text-block {
  text-align: left;
}

.Square {
  aspect-ratio : 1 / 1;
}

.No-top-border {
  border: 0px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Card-footer {
  color: #525252;
}

.No-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Category-switch-inactive {
  text-decoration: none;
}

.Category-switch-active {
  text-decoration: none;
  background-color: #DF739E1A !important;
  color: #F96D5D !important;
}

.Menu-dropdown-button {
  color: #F96D5D !important;
}

.App-link {
  color: #61dafb;
}

.Token-attribute-table-title {
  color: #748296;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 2.5em;
}

.Page-subheader{
  color: #848484;
  font-size: 0.8em;
}

.TokenGridBackground{
  background-color: #EFF4FA;
  background: radial-gradient(circle, rgba(255,195,145,0.5) 20%, rgba(221,233,245,1) 100%);

  background-size: cover;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
}

i.icon {
  margin: 0em 0.25rem 0em 2rem;
}

i.caret.down.icon{
  margin: 0;
  padding: 0.7em 1em 0.7em 1em;
  height: auto;
  border-radius: 0.2307em
}

i.caret.down.icon:hover{
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: #f96d5d;
  text-decoration: underline;
}

.attention-color {
  color: #f96d5d;
}

.Token-attribute-table-value {
  color: #000000;
  font-size: 0.9em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MetadataTemplateButton {
  margin: 0.5em !important;
}

/* gives us the illusion of a "centered" slide 
with preview of previous and next slide */
.carousel__slider {
  padding-left: 20%;
  padding-right: 20%;
}


.OnboardingCarouselBackground {
  background-image: url('images/slide_gradient_background.png') !important;
  border-radius: 2em !important;
  padding: 4vw;
  margin: 2vw 3vw 1vw 3vw;
}

.OnboardingCarouselTextParagraph {  
  text-align: left;
  font-size: 0.9em;
}

.OnboardingCarouselSlideIndex {
  font-size: 0.7em;
  padding: 1.5em;
  color: #848484;

}

.EndorsementCarouselCard {
  padding: 2em 2em 2em 2em;
}

.EndorsementCarouselCardContent {
  color: #FFFFFF;
  background-color: #FFFFFF33;
  font-size: 1.5em;
  border-radius: 2em;
  padding: 2em;
  min-height: 10em;
}

.EndorsementCarouselCardContent a {
  color: #DF739E;
  text-decoration: none;
}

.EndorsementCarouselBackground {
  background: rgb(223,115,158);
  background: linear-gradient(83deg, rgba(223,115,158,0.5) 0%, rgba(43,78,230,0.5) 100%);
  border-radius: 2em !important;
}

:root {
  --endorsement-carousel-visible-cards: 2;
}

@media only screen and (max-width: 767px) {
  
  .EndorsementCarouselCard {
    padding: 0.2em 0.2em 0.2em 0.2em;
  }

  .EndorsementCarouselCardContent {
    font-size: 1.2em;
    padding: ß.2em;
    min-height: 10em;
  }

  :root {
    --endorsement-carousel-visible-cards: 1;
  }

}

.FormSubLabel {
 color: #748296;
}

.margin-vertical {
  margin: 10px 0px
}

.margin-vertical-main-menu {
  margin: 2vh 0vh
}

.margin-horizontal {
  margin: 0px 10px
}

.margin-bottom {
  margin: 0px 10px 0px 0px 
}

.top-level-page {
  margin: 5vh 20vw 5vh 20vw
}

.Footer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #152F3D;
  padding: 5vh 10vw;
  color: #FFFFFF;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.FooterText {
  color: #FFFFFF !important;

}

.Footer div.divider {
  background: #969696;
  border: 1px solid #969696;
}

.Footer img.talko {
  margin-bottom: 57px;
}

.Footer img.eu-flag {
  margin-top: 0;
}

.CarouselOverlayGradient {
  background: linear-gradient(90deg, rgba(255,255,255,125) 0%, rgba(255,255,255,0) 30%,rgba(255,255,255,0) 70%, rgba(255,255,255,125) 100%);
}

.LinkWithoutLinkFormatting {
  color: inherit;
  text-decoration: inherit;
}

.ExpandableTitleIconSpan {
  text-align:right;
  white-space: nowrap;
  float: right;
}
